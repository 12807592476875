import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
import Callout from '../../components/Callout';
import CodeBlock from '../../components/CodeBlock';
import BrowserGlobalsCodeBlock from '../../components/BrowserGlobalsCodeBlock';
import CssCodeBlock from '../../components/CssCodeBlock';
import DocLink from '../../components/DocLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <p className="lead">Learn how to include React Bootstrap in your project</p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`The best way to consume React-Bootstrap is via the npm package which
you can install with `}<inlineCode parentName="p">{`npm`}</inlineCode>{` (or `}<inlineCode parentName="p">{`yarn`}</inlineCode>{` if you prefer).`}</p>
    <p>{`If you plan on customizing the Bootstrap Sass files, or don't want
to use a CDN for the stylesheet, it may be helpful to
install `}<DocLink path="/getting-started/download/#npm" mdxType="DocLink">{`vanilla Bootstrap`}</DocLink>{` as well.`}</p>
    <CodeBlock codeText={`npm install react-bootstrap bootstrap@${config.bootstrapVersion}`} mdxType="CodeBlock" />
    <h2 {...{
      "id": "importing-components"
    }}>{`Importing Components`}</h2>
    <p>{`You should import individual components like:
`}<inlineCode parentName="p">{`react-bootstrap/Button`}</inlineCode>{` rather than the entire library.
Doing so pulls in only the specific components that you use, which
can significantly reduce the amount of code you end up sending to
the client.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Button from 'react-bootstrap/Button';

// or less ideally
import { Button } from 'react-bootstrap';
`}</code></pre>
    <h3 {...{
      "id": "browser-globals"
    }}>{`Browser globals`}</h3>
    <p>{`We provide `}<inlineCode parentName="p">{`react-bootstrap.js`}</inlineCode>{` and
`}<inlineCode parentName="p">{`react-bootstrap.min.js`}</inlineCode>{` bundles with all components
exported on the `}<inlineCode parentName="p">{`window.ReactBootstrap`}</inlineCode>{` object. These
bundles are available on `}<a parentName="p" {...{
        "href": "https://unpkg.com/react-bootstrap/"
      }}>{`unpkg`}</a>{`, as
well as in the npm package.`}</p>
    <BrowserGlobalsCodeBlock mdxType="BrowserGlobalsCodeBlock" />
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <p>{`React-Bootstrap has started a repo with a few basic CodeSandbox examples.
`}<a parentName="p" {...{
        "href": "https://github.com/react-bootstrap/code-sandbox-examples/blob/master/README.md"
      }}>{`Click here`}</a>{`
to check them out.`}</p>
    <h2 {...{
      "id": "stylesheets"
    }}>{`Stylesheets`}</h2>
    <p>{`Because React-Bootstrap doesn't depend on a very precise version of
Bootstrap, we don't ship with any included CSS. However, some
stylesheet `}<strong parentName="p">{`is required`}</strong>{` to use these components.`}</p>
    <h3 {...{
      "id": "css"
    }}>{`CSS`}</h3>
    <pre><code parentName="pre" {...{}}>{`{/* The following line can be included in your src/index.js or App.js file*/}

import 'bootstrap/dist/css/bootstrap.min.css';
`}</code></pre>
    <p>{`How and which Bootstrap styles you include is up to you, but the
simplest way is to include the latest styles from the CDN. A little
more information about the benefits of using a CDN can be found
`}<a parentName="p" {...{
        "href": "https://www.w3schools.com/bootstrap/bootstrap_get_started.asp"
      }}>{`here`}</a>{`.`}</p>
    <CssCodeBlock mdxType="CssCodeBlock" />
    <h3 {...{
      "id": "sass"
    }}>{`Sass`}</h3>
    <p>{`In case you are using `}<strong parentName="p">{`Sass`}</strong>{` the simplest way is to include the Bootstrap’s source Sass files
in your main Sass file and then require it on your `}<inlineCode parentName="p">{`src/index.js`}</inlineCode>{` or `}<inlineCode parentName="p">{`App.js`}</inlineCode>{` file.
This applies to a typical `}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{` application in other use cases you might have to setup
the bundler of your choice to compile Sass/SCSS stylesheets to CSS.`}</p>
    <pre><code parentName="pre" {...{}}>{`/* The following line can be included in a src/App.scss */

@import "~bootstrap/scss/bootstrap";

/* The following line can be included in your src/index.js or App.js file */

import './App.scss';
`}</code></pre>
    <h4 {...{
      "id": "customize-bootstrap"
    }}>{`Customize Bootstrap`}</h4>
    <p>{`If you wish to customize the Bootstrap theme or any Bootstrap variables
you can create a custom Sass file:`}</p>
    <pre><code parentName="pre" {...{}}>{`/* The following block can be included in a custom.scss */

/* make the customizations */
$theme-colors: (
    "info": tomato,
    "danger": teal
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
`}</code></pre>
    <p>{`... And import it on the main Sass file.`}</p>
    <pre><code parentName="pre" {...{}}>{`/* The following line can be included in a src/App.scss */

@import "custom";
`}</code></pre>
    <h3 {...{
      "id": "advanced-usage"
    }}>{`Advanced usage`}</h3>
    <p>{`See `}<a parentName="p" {...{
        "href": "https://getbootstrap.com/docs/4.4/getting-started/theming/"
      }}>{`the Bootstrap docs`}</a>{`
for more advanced use cases and details about customizing stylesheets.`}</p>
    <h2 {...{
      "id": "themes"
    }}>{`Themes`}</h2>
    <p>{`React-Bootstrap is compatible with existing Bootstrap themes. Just
follow the installation instructions for your theme of choice.`}</p>
    <Callout theme="danger" title="Watch out!" mdxType="Callout">
  Because React-Bootstrap completely reimplements Bootstrap's JavaScript, it's
  not automatically compatible with themes that extend the default JavaScript
  behaviors.
    </Callout>
    <Callout theme="warning" title="Create React App" mdxType="Callout">
      <p>{`If you would like to add a custom theme on your app using Create React
App, they have additional documentation for Create React App and
Bootstrap
`}<a parentName="p" {...{
          "href": "https://facebook.github.io/create-react-app/docs/adding-bootstrap#using-a-custom-theme"
        }}>{`here`}</a></p>
    </Callout>
    <h2 {...{
      "id": "browser-support"
    }}>{`Browser support`}</h2>
    <p>{`We aim to support all browsers supported by both
`}<a parentName="p" {...{
        "href": "http://facebook.github.io/react/docs/working-with-the-browser.html#browser-support-and-polyfills"
      }}>{`React`}</a>{`
and `}<DocLink path="/getting-started/browsers-devices/#supported-browsers" mdxType="DocLink">{`Bootstrap`}</DocLink>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      